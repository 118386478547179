.navigation-index {
  background-color: $navigation-index-bg;
  @include media-breakpoint-up(lg) {
    background-color: transparent;
    position: relative;
    overflow: hidden;
  }

  ul {
    padding-bottom: $navigation-index-padding-y;
    padding-top: $navigation-index-padding-y;
    @include media-breakpoint-up(lg) {
      background-color: $navigation-index-bg;
      padding-bottom: ($navigation-index-padding-y / 1.48);
      padding-top: ($navigation-index-padding-y / 1.48);
      padding-left: $navigation-index-ul-before-width-lg;
      position: relative;
      &::before {
        bottom: 0;
        background-color: $navigation-index-ul-before-bg;
        content: '';
        left: ($navigation-index-ul-before-skew-lg * -1px);
        position: absolute;
        top: 0;
        transform: skew($navigation-index-ul-before-skew-lg * -1deg);
        width: $navigation-index-ul-before-width-lg;
      }
      &::after {
        background-color: $navigation-index-bg;
        bottom: 0;
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        width: 1000%;
      }
    }
    @include media-breakpoint-up(xl) {
      padding-left: $navigation-index-ul-before-width-xl;
      &::before {
        width: $navigation-index-ul-before-width-xl;
      }
    }
  }

  li {
    margin: $navigation-index-li-margin-y $navigation-index-li-margin-x;
    @include media-breakpoint-up(sm) {
      margin-left: ($navigation-index-li-margin-x * 2.5);
      margin-right: ($navigation-index-li-margin-x * 2.5);
    }
    @include media-breakpoint-up(md) {
      margin-left: ($navigation-index-li-margin-x * 2);
      margin-right: ($navigation-index-li-margin-x * 2);
    }
    @include media-breakpoint-up(lg) {
      margin-left: ($navigation-index-li-margin-x * 8);
      margin-right: 0;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  a {
    color: $navigation-index-color;
  }
}
