.item {
  margin-bottom: $item-margin-y;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: $item-width;

  &__title {
    border-bottom: $item-title-border-width $item-title-border-style $item-title-border-color;
    font-size: $item-title-font-size;
    padding: $item-title-padding-y $item-title-padding-x;
    user-select: none;
    @include media-breakpoint-up(lg) {
      @include text-truncate;
    }
    @include media-breakpoint-between(lg,lg) {
      font-size: 120%;
      padding-bottom: ($item-title-padding-y / 1.5);
      padding-top: ($item-title-padding-y / 1.5);
    }
  }

  &__body {
    min-height: $item-body-min-height;
    overflow: hidden;
    position: relative;
    @include media-breakpoint-between(lg,lg) {
      min-height: $item-body-min-height-lg;
    }
    @include media-breakpoint-between(md,md) {
      min-height: $item-body-min-height-md;
    }
    &::after {
      content: '';
      height: 100%;
      left: -50px;
      pointer-events: none;
      position: absolute;
      right: -50px;
      top: 100%;
      opacity: 0.85;
    }
    > img {
      @include size(100%);
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
    }
  }

  &__footer {
    font-size: $item-footer-font-size;
    padding-bottom: $item-footer-padding-y;
    padding-top: $item-footer-padding-y;
    position: relative;
    z-index: 1;
    line-height: 1;

    [class*="display-"] {
      line-height: 1;
      font-size: 3.35rem;
    }

    @include media-breakpoint-between(md,lg) {
      font-size: 90%;

      [class*="display-"] {
        line-height: 1;
        font-size: 2.5rem;
      }
    }
  }

  &__act {
    font-size: $item-act-font-size;
  }
}
