//
// Links
//

a {
  transition: $link-transition;
}

// Replace links text-decoration with border-bottom

a:not([class*="btn"]):not(.navbar-brand):not(.d-block):not(.nav-link) {
  border-bottom: $link-border-width $link-border-style;

  @include hover {
    border-bottom-color: transparent;
  }
}
