// Responsive display's

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .btn#{$infix}-block { display: block !important; width: 100%; }
    .btn#{$infix}-inline-block { display: inline-block !important; width: auto; }
  }
}
