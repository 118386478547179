.benefit {

  max-width: 320px;
  margin-left: auto;
  margin-right: auto;

  &__title {
    min-height: 54px;
  }
}
