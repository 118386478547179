// Row
//
// Rows contain and clear the floats of your columns.

.small-gutters {
  margin-right: ($grid-gutter-width-small / -2);
  margin-left: ($grid-gutter-width-small / -2);

  > .col,
  > [class*="col-"] {
    padding-right: ($grid-gutter-width-small / 2);
    padding-left: ($grid-gutter-width-small / 2);
  }
}

.large-gutters {
  margin-right: ($grid-gutter-width-large / -2);
  margin-left: ($grid-gutter-width-large / -2);

  > .col,
  > [class*="col-"] {
    padding-right: ($grid-gutter-width-large / 2);
    padding-left: ($grid-gutter-width-large / 2);
  }
}
