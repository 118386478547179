//
// Lists
//

.list-check {
  @include list-unstyled;

  li {
    margin-bottom: $list-check-li-marigin-y;
    padding-left: $list-check-li-padding-left;
    position: relative;
    &::before {
      @include size($list-check-li-before-size);
      background: url($list-check-li-before-bg-img) no-repeat left top / $list-check-li-before-size;
      content: '';
      left: 0;
      position: absolute;
    }
  }
}


//
// Headings
//

h2, .h2 {
  @include media-breakpoint-down(xs) {
    font-size: 2rem;
  }
}
