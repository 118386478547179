.footer {
  background-color: $footer-bg;
  background: linear-gradient(to bottom, darken($footer-bg, 7%) 0%,$footer-bg 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{darken($footer-bg, 7%)}', endColorstr='#{$footer-bg}',GradientType=0 );
  color: $footer-color;
  line-height: $footer-line-height;
  padding-top: $footer-padding-y;
  padding-bottom: ($footer-padding-y / 2);
  @include media-breakpoint-up(xl) {
    padding-bottom: 0;
  }

  a {
    @include plain-hover-focus {
      color: $footer-link-color;
    }
  }

  &__item {
    padding-bottom: $footer-padding-y;

    &--identity {
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
      @include media-breakpoint-up(sm) {
        max-width: 100%;
      }
      @include media-breakpoint-up(xl) {
        padding-bottom: ($footer-padding-y / 2);
      }
    }
  }

  &__nav {

    ul {
      font-size: $footer-nav-font-size;
      margin-bottom: 0;
    }

    li {
      margin: $footer-nav-li-margin-y;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    a {
      @include plain-hover-focus {
        color: $footer-color;
      }
    }
  }
}
