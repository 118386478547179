.section {
  background-color: $section-bg;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down('sm') {
    // Override bg-image from style attribute
    background-image: none !important;
  }
  @include media-breakpoint-up('md') {
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
    min-height: $section-min-height-md;
  }
  @include media-breakpoint-up('xl') {
    background-size: 75% $section-min-height-xl;
    min-height: $section-min-height-xl;
  }

  &::before {
    @include media-breakpoint-up('md') {
      @include size(100%);
      background: $section-bg;
      bottom: 0;
      content: '';
      position: absolute;
      right: $section-before-position-x-md;
      top: 0;
      transform: skew($section-before-skew-md * -1);
    }
    @include media-breakpoint-up('lg') {
      right: $section-before-position-x-lg;
    }
    @include media-breakpoint-up('xl') {
      @include size(100%, $section-min-height-xl);
      right: $section-before-position-x-xl;
      transform: skew($section-before-skew-xl * -1);
    }
  }

  &--left {
    background-position: left top;
    &::before {
      @include media-breakpoint-up('md') {
        transform: skew($section-before-skew-md);
        left: $section-before-position-x-md;
        right: auto;
      }
      @include media-breakpoint-up('lg') {
        left: $section-before-position-x-lg;
      }
      @include media-breakpoint-up('xl') {
        transform: skew($section-before-skew-xl);
        left: ($section-before-position-x-xl + 0.65%);
      }
    }
  }

  &:not(:last-child) {
    @include media-breakpoint-up('md') {
      padding-bottom: $section-padding-y-md;
      &::after {
        @include size(100%, $section-padding-y-md);
        background-color: $section-bg;
        bottom: 0;
        position: absolute;
        content: '';
      }
    }
  }

  &__item {
    background-color: $section-bg;
    @include media-breakpoint-up('md') {
      background-color: transparent;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    padding-bottom: $section-title-padding-y;
    padding-top: $section-title-padding-y;
    position: relative;

    &::before {
      background-color: rgba($primary, 0.3);
      bottom: 0;
      content: '';
      left: -1000%;
      position: absolute;
      right: -1000%;
      top: 0;
    }

    span {
      position: relative;
    }
  }
}

.section--blue-muted {
  background-color: rgba($section-blue-muted-bg, 0.1);
}

// Titles bg colors

@each $color, $value in $section-colors {
  .section:nth-child(#{$color}n) .section__title::before {
    background-color: rgba($value, $section-title-bg-opacity);
  }
}
