.items {
  padding-top: $items-padding-y;
}

// Titles and bg colors

@each $color, $value in $items-colors {
  .items__box:nth-child(#{$color}n) .item__title {
    background-color: $value;
  }
  .items__box:nth-child(#{$color}n) .item__body::after {
    background-color: $value;
    box-shadow: 0 -170px 170px $value;
  }
}
