.navigation-secondary {
  background-color: $navigation-secondary-bg;

  ul {
    padding-bottom: $navigation-secondary-padding-y;
    padding-top: $navigation-secondary-padding-y;
    @include media-breakpoint-up(lg) {
      padding-bottom: ($navigation-secondary-padding-y / 1.48);
      padding-top: ($navigation-secondary-padding-y / 1.48);
    }
  }

  li {
    margin: $navigation-secondary-li-margin-y $navigation-secondary-li-margin-x;
    @include media-breakpoint-up(sm) {
      margin-left: ($navigation-secondary-li-margin-x * 2.5);
      margin-right: ($navigation-secondary-li-margin-x * 2.5);
    }
    @include media-breakpoint-up(md) {
      margin-left: ($navigation-secondary-li-margin-x * 2);
      margin-right: ($navigation-secondary-li-margin-x * 2);
    }
    @include media-breakpoint-up(lg) {
      margin-right: ($navigation-secondary-li-margin-x * 8);
      margin-left: 0;
      position: relative;
      &::before {
        @include size(3px);
        background-color: $navigation-secondary-color;
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  a {
    border-color: $navigation-secondary-color;
    color: $navigation-secondary-color;
  }
}
