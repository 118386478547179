// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

$white: #fff;
$gray-600: #737373;
$gray-640: #5f6e7a;
$gray-650: #567084;
$gray-700: #404040;

$blue: #1a5f96;
$blue-light: #a8d9f6;
$blue-dark: #014a80;
$blue-muted: #3b739f;
$blue-2: #406184;
$red: #8d3305;
$green: #058d83;
$zomp: #3b9f8a;
$california-gold: #af952a;
$metallic-sunburst: #a26637;
$anti-flash-white: #eef2f5;

$primary: $blue;
$primary-light: $blue-light;
$primary-dark: $blue-dark;

$page-bg: $anti-flash-white;

$theme-colors: ();
$theme-colors: map-merge((
  "primary-light": $primary-light,
  "primary-dark": $primary-dark
), $theme-colors);


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: false;


// Body
//
// Settings for the `<body>` element.

$body-bg: $gray-650;
$body-color: $gray-700;


// Links
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: none;
$link-border-width: 0.063rem;
$link-border-style: solid;
$link-transition: color 0.3s ease-out, border-color 0.5s ease-out;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1.6rem !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// Fonts
$font-family-sans-serif: 'Cuprum', Arial, sans-serif;
$font-family-sans-serif-secondary: 'Europe', Arial, sans-serif;

$font-family-base: $font-family-sans-serif;

$font-size-base: 1.125rem;

$line-height-base: 1.33333;

$h3-font-size: $font-size-base * 1.7;

// Lists

$list-check-li-marigin-y: 1.313rem;
$list-check-li-padding-left: 2.5rem;

$list-check-li-before-bg-img: '../img/check.png';
$list-check-li-before-size: 27px;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;

$grid-gutter-width: 30px;
$grid-gutter-width-small: 12px;
$grid-gutter-width-large: 50px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5;


// Buttons + Forms

$input-btn-padding-y-lg: 0.563rem;
$input-btn-padding-x-lg: 1.748rem;


// Buttons

$btn-outline-white-hover-color: $gray-700;


// Navbar

$navbar-brand-font-size: 2.2104rem;
$navbar-brand-font-family: $font-family-sans-serif-secondary;


// Cards

$card-border-width: 0;


// Item
$item-margin-y: 1.063rem;
$item-width: 393px;

$item-title-font-size: 1.625rem;
$item-title-padding-y: 1.625rem;
$item-title-padding-x: 1rem;
$item-title-border-width: 1px;
$item-title-border-color: $white;
$item-title-border-style: solid;

$item-body-min-height: 22.438rem;
$item-body-min-height-md: 20rem;
$item-body-min-height-lg: 17rem;

$item-footer-padding-y: 1.5rem;
$item-footer-padding-x: 1.375rem;
$item-footer-font-size: 1.266rem;

$item-act-font-size: 1.368rem;

// Partials


// Header

$header-bg: $white;
$header-box-shadow: 0 5px 10px rgba(#000, 0.13);
$header-min-height: 148px;
$header-padding-y: 1.5rem;
$header-line-height: ($line-height-lg * 0.95);


// Footer

$footer-bg: $body-bg;
$footer-color: $white;
$footer-line-height: ($line-height-lg * 1.05);
$footer-padding-y: 1.875rem;

$footer-link-color: $blue-light;

$footer-nav-font-size: ($font-size-base * 1.11111);
$footer-nav-li-margin-y: 5px;
$footer-nav-li-margin-x: $footer-nav-li-margin-y;


// Section

$section-bg: $white;
$section-blue-muted-bg: $blue-muted;
$section-min-height-md: 306px;
$section-min-height-xl: 406px;
$section-padding-y-md: 15px;

$section-before-skew-md: 15deg;
$section-before-skew-xl: 23deg;
$section-before-position-x-md: 30%;
$section-before-position-x-lg: 40%;
$section-before-position-x-xl: 50%;

$section-title-padding-y: $section-padding-y-md;
$section-title-bg-opacity: 0.3;

//Create Colors Array
$section-colors: ();
$section-colors: map-merge((
  "1": $primary,
  "2": $zomp,
  "3": $california-gold,
  "4": $metallic-sunburst
), $section-colors);


// Items

$items-padding-y: 1rem;

//Create Colors Array
$items-colors: ();
$items-colors: map-merge((
  "1": $primary,
  "2": $green,
  "3": $red,
), $items-colors);

// Navigation

// Index nav
$navigation-index-bg: $gray-640;
$navigation-index-color: $white;
$navigation-index-padding-y: 1.25rem;

$navigation-index-ul-before-bg: $page-bg;
$navigation-index-ul-before-width-lg: 150px;
$navigation-index-ul-before-width-xl: 320px;
$navigation-index-ul-before-skew-lg: 30;

$navigation-index-li-margin-y: 5px;
$navigation-index-li-margin-x: $navigation-index-li-margin-y;

// Secondary nav
$navigation-secondary-bg: rgba($section-blue-muted-bg, 0.18);
$navigation-secondary-color: $blue-2;
$navigation-secondary-padding-y: $navigation-index-padding-y;

$navigation-secondary-li-margin-y: $navigation-index-li-margin-y;
$navigation-secondary-li-margin-x: $navigation-secondary-li-margin-y;
// Pages
