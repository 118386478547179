.tabs-section {
  overflow: hidden;

  li {

    display: flex;
    margin-bottom: -1px;
    min-height: 1px;
    padding-bottom: 15px;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(sm) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      padding-bottom: 4px;

      &:not(:first-child) {
        padding-left: 2px;
      }
      &:not(:last-child) {
        padding-right: 2px;
      }
    }
  }

  li a {
    align-items: center;
    border: 0 !important;
    color: $white;
    display: flex;
    justify-content: center;
    padding: .85rem 1rem;
    position: relative;
    text-transform: uppercase;
    transition: background-color 0.9s ease;
    width: 100%;
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(sm) {
      &::before {
        border-color: transparent;
        border-style: solid;
        border-width: 20px 25px 0 25px;
        content: '';
        height: 0;
        left: 50%;
        pointer-events: none;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        width: 0;
        z-index: 9999;
        transition: 0.2s ease;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 4px;
        bottom: -4px;
        transition: 0.2s ease;
        pointer-events: none;
      }
    }
  }
}

// Titles and bg colors

@each $color, $value in $items-colors {
  .tabs-section li:nth-child(#{$color}n) {

    .nav-link, a {
      background-color: lighten(rgba(mix($value, black, 75%), 0.45), 5%);
      &:hover {
        background-color: lighten(rgba(mix($value, black, 75%), 0.65), 5%);
      }
    }

    &.active {
      .nav-link, a {
        background-color: lighten(rgba(mix($value, black, 75%), 1), 5%);
        &::before {
          border-color: lighten(rgba(mix($value, black, 75%), 1), 5%) transparent transparent transparent;
        }
        &::after {
          background-color: lighten(rgba(mix($value, black, 75%), 1), 5%);
        }
      }
    }
  }
}
