// Buttons
//
// Custom buttons

.btn-outline-white {
  font-weight: 500;
  color: $white;
  background-color: transparent;
  border-color: $white;

  &:hover,
  &:active {
    color: $btn-outline-white-hover-color;
    background-color: $white;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($white, .25);
  }
}
