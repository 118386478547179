// Contents
//
// Navbar
// Navbar brand
// Navbar text


// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
  display: block;
  margin-right: $navbar-padding-x;
  font-family: $navbar-brand-font-family;

  @include hover-focus {
    text-decoration: none;
  }
}

// Navbar text
//
//

.navbar-text {
  display: inline-block;
  padding-top: 0.05em;
  padding-bottom: $nav-link-padding-y;
}
