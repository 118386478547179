.header {
  background-color: $header-bg;
  box-shadow: $header-box-shadow;
  line-height: $header-line-height;
  min-height: $header-min-height;
  padding-top: $header-padding-y;
  position: relative;
  z-index: 1;

  &__item {
    padding-bottom: ($header-padding-y / 1.4);

    &--identity {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
      @include media-breakpoint-up(sm) {
        max-width: 100%;
      }
    }
  }
}
